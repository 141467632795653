import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import styled from 'styled-components';

import { MenuButton } from 'src/components/common/menu-button';
import { ScreenReaderTextStyle } from 'src/components/common/screen-reader-text';
import type { MenuProps } from 'src/constants';
import { DEFAULT_MENUS } from 'src/constants';
import CoconogaccoFoundationLogo from 'src/images/coconogacco-foundation-logo.jpg';
import { media } from 'src/styles';
import {
  getLangPath,
  useIsMobile,
  useNewsCategoryMenus,
  useSlug,
} from 'src/utils';
import { Languages } from './languages';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
};

const useMenus = (menus: MenuProps[]) => {
  const { lang } = useSlug();
  return useMemo(
    () =>
      menus.map((menu) => ({
        ...menu,
        ...{ href: getLangPath({ path: menu.href, lang }) },
      })),
    [menus, lang]
  );
};

const useMainMenus = () => {
  const defaultNewsCategoryMenus = useNewsCategoryMenus();
  const newsCategoryMenus = useMenus(defaultNewsCategoryMenus);
  const mainMenus = useMenus(DEFAULT_MENUS.MAIN);
  return mainMenus.map((menu) => {
    if (menu.name !== 'news') {
      return menu;
    } else {
      return { ...menu, ...{ children: newsCategoryMenus } };
    }
  });
};

const useIsShownMenuForSp = () => {
  const { pathname } = useSlug();
  const [isShownMenu, setIsShownMenu] = useState(false);
  const toggleMenu = useCallback(() => {
    setIsShownMenu(!isShownMenu);
  }, [isShownMenu]);
  useEffect(() => {
    setIsShownMenu(false);
  }, [pathname]);
  return { isShownMenu, toggleMenu };
};

export const Navigation: FC<Props> = ({ className }) => {
  const mainMenus = useMainMenus();
  const subMenus = useMenus(DEFAULT_MENUS.SUB);
  const isMobile = useIsMobile();
  const { isShownMenu, toggleMenu } = useIsShownMenuForSp();
  return (
    <Wrapper role="navigation" className={className || ''}>
      {isMobile && (
        <StyledMenuButton onClick={toggleMenu} isPressed={isShownMenu} />
      )}
      <MenuWrapper className={isMobile && isShownMenu ? 'shown' : undefined}>
        <MainMenuList menus={mainMenus} />
        <SubMenuList menus={subMenus} isSubMenu={true} />
        <StyledLanguages />
        <CoconogaccoFoundationLink
          // href="https://foundation.coconogacco.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={CoconogaccoFoundationLogo} alt="coconogacco foundation" />
        </CoconogaccoFoundationLink>
      </MenuWrapper>
    </Wrapper>
  );
};

const StyledMenuButton = styled(MenuButton)`
  ${media.lessThanIpadVertical`
    position: absolute;
    z-index: 10;
    top: ${({ theme }) => theme.structure.margin.top.sp}px;
    left: ${({ theme }) => theme.structure.margin.vertical.sp}px;
  `}
`;

const MainMenuList = styled(MenuList)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const SubMenuList = styled(MenuList)`
  ${media.lessThanIpadVertical`
    margin-top: 50px;
  `}
  ${media.ipadVerticalOrMore`
    margin-top: 70px;
  `}
`;

const StyledLanguages = styled(Languages)`
  margin-top: ${({ theme }) => theme.structure.margin.block.small}px;
`;

const CoconogaccoFoundationLink = styled.a`
  display: block;
  margin-top: ${({ theme }) => theme.structure.margin.block.small}px;
  width: 165px;
`;

const MenuWrapper = styled.div`
  ${media.lessThanIpadVertical`
    &:not(.shown) {
      ${ScreenReaderTextStyle}
    }
    &.shown {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: #ffffff;
      width: 50vw;
      height: 100vh;
      padding-top: 115px;
      padding-left: ${({ theme }) => theme.structure.margin.vertical.sp}px;
    }
  `}
`;

const Wrapper = styled.nav`
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: ${({ theme }) => theme.font.size.large};
`;

export default Navigation;
